import { goto } from '$app/navigation'
import type { ToastStore } from '@skeletonlabs/skeleton'
import camelcaseKeys from 'camelcase-keys'
import type { User as FirebaseUser } from 'firebase/auth'
import {
  browserLocalPersistence,
  getAuth,
  getIdToken,
  onAuthStateChanged,
  setPersistence,
  signOut,
} from 'firebase/auth'

import { handleFetch } from './fetch'
import { loggedInStore, User } from '../stores/logged-in-store'

export const HIRUNDO_EMAIL_ENDING = '@hirundo.io'

export const isHirundoEmail = (email: string | undefined): boolean =>
  email?.toLowerCase()?.endsWith(HIRUNDO_EMAIL_ENDING) ?? false

export const airGappedUserPasswordLocalStorage = 'airGappedUserPassword'
export const setAirGappedUserPassword = (username: string, password: string) => {
  localStorage.setItem(airGappedUserPasswordLocalStorage, btoa(`${username}:${password}`))
}

let addedTokenChangeListener = false

export const forceLogout = async () => {
  try {
    const auth = getAuth()
    await signOut(auth)
    await goto('/login', { invalidateAll: true })
  } catch (e) {
    console.error('Failed to sign out', e)
  }
  localStorage.setItem('authUser', '')
  loggedInStore.update(User.logout())
}

const refreshIdToken = async (user: FirebaseUser, toastStore: ToastStore, forceRefresh = false) => {
  const idToken = (await getIdToken(user, forceRefresh)) ?? ''
  if (!idToken) {
    return await forceLogout()
  }
  try {
    const loginResult = await (
      await handleFetch('/user/', {
        method: 'POST',
        body: { idToken },
      })
    ).json()
    localStorage.setItem(
      'authUser',
      JSON.stringify({
        email: user.email,
        loginResult,
      }),
    )
    if (!user.email) {
      console.error('Failed to get email from user info', user)
      toastStore?.trigger({
        message: 'Failed to user info. Please contact Hirundo support.',
        background: 'variant-filled-error',
      })
      return
    }
    loggedInStore.update(User.login(user.email, camelcaseKeys(loginResult)))
  } catch (e) {
    console.error('Failed to get user info', e)
    return await forceLogout()
  }
}

const onIdTokenRevocation = (toastStore: ToastStore) => async (user: FirebaseUser | null) => {
  if (user) {
    await refreshIdToken(user, toastStore)
    return
  }
  // For an email/password user. Prompt the user for the password again.
  toastStore?.trigger({
    message: 'Please re-authenticate',
    background: 'variant-filled-warning',
  })
  return await forceLogout()
}

export const checkAuth = async (toastStore: ToastStore) => {
  const auth = getAuth()
  setPersistence(auth, browserLocalPersistence) // <- This should be the default, but just in case
  // ⬆️ Persistence `LOCAL` ensures that the user is not logged out when the browser is closed
  await auth.authStateReady()
  if (!auth.currentUser) {
    await forceLogout()
    throw new Error('No user logged in')
  }

  if (!addedTokenChangeListener) {
    onAuthStateChanged(auth, onIdTokenRevocation(toastStore))
    addedTokenChangeListener = true
  }
  await refreshIdToken(auth.currentUser, toastStore, true)
  setInterval(
    () => {
      if (auth.currentUser) {
        refreshIdToken(auth.currentUser, toastStore)
      }
    },
    59 * 60 * 1_000,
  )
  // ⬆️ Refresh the token automatically every hour (at 59 mins) to avoid the user being logged out
  //   when making a request after the token has expired
}

export const PRE_AUTH_ROUTES = ['/login', '/signup']
